import {
  useLocation,
  useNavigate,
  useRoutes,
  useSearchParams,
} from "react-router-dom";
import { Interface } from "readline";
import styles from "../styles/Index.module.css";
import ButtonDef from "./Button";
import { ContactRequest, ContactUs } from "./ContactForm";

const texts = {
  title1: "O que é o GPS?",
  descriptionOld: `O GPS - Guia Para Serviços - surge a partir de um processo co construído pela comunidade e
    organizações, para responder à necessidade de tornar mais acessível a informação sobre os
    serviços e respostas existentes nas freguesias, bem como sobre os seus modos de
    funcionamento. Considerando os princípios da Diversidade e Pluralismo, o GPS disponibiliza
    uma linguagem acessível e culturalmente sensível, a identificação e geo-localização de serviços
    e respostas de diversas áreas especificamente nas freguesias de Algueirão Mem Martins, Queluz e Belas e Rio
    de Mouro.`,
  description1: `Este projeto foi financiando pelo Programa Contracto Local de Desenvolvimento Social 4G do
    Instituto da Segurança Social e pelas Juntas de Freguesia de Algueirão-Mem Martins, Rio de
    Mouro e Queluz e Belas estiveram envolvidos 20 multiplicadores comunitários (moradores
    agentes ativos de mudança, de referência para a comunidade onde vivem) e as seguintes
    organizações: Junta das Freguesias de Algueirão-Mem Martins, Rio de Mouro, Queluz e Belas,
    Fundação Aga Khan Portugal, Câmara Municipal de Sintra, Associação A Comunidade Islâmica
    da Tapada das Mercês e Mem Martins, Escola Visconde de Juromenha, Centro Social e
    Paroquial de Algueirão-Mem Martins, Agrupamento de Escolas Leal da Câmara, Associação
    Juvenil A Ponte, Projeto Orienta.te E8G, Associação para a Promoção do Desenvolvimento
    Juvenil, Associação Criagente, Associação Olho Vivo, Associação Ajuda de Mãe.`,
  title2: "Como utilizar o GPS?",
  descriptionOld2: `O GPS está dividido por três freguesias Algueirão Mem Martins, Queluz e Belas e Rio de
    Mouro. Para procurar a resposta que precisa terá de:;
    ;
    1. Escolher a freguesia onde pretende procurar as respostas que precisa;
    ;
    2. Pode optar por selecionar a categoria (Crianças, Jovens, Famílias e Séniores) em que
    procura respostas;
    ;
    3. De seguida, vai encontrar as temáticas das respostas (Apoio Alimentar, Apoio
    Psicológico, etc.);
    ;
    4. Se ainda assim não encontrar o que pretende, poderá escrever na barra de pesquisa
    (lupa) uma palavra relacionada com a resposta que pretende encontrar (alertamos que
    esta última forma de pesquisa pode ser sensível à forma como a palavra está escrita e,
    nestes casos, aconselhamos a voltar à pesquisa por Freguesia);
    5. Por fim, irá encontrar os vários serviços e respostas com as informações e localização.`,
  title3: "Junte-se ao GPS",
  description2:
    "Ajude o GPS a crescer! Se faz parte de uma organização e quer ver a sua resposta aqui.",
  title4: "Como chegámos a esta ideia?",
  description4: `Neste processo estiveram envolvidos cerca de 20 multiplicadores comunitários (moradores
        agentes ativos de mudança, de referência para a comunidade onde vivem) e as seguintes
        organizações: Câmara Municipal de Sintra, Junta de Freguesia de Algueirão Mem Martins,
        União das Freguesias de Queluz e Belas, Junta de Freguesia de Rio de Mouro, Fundação Aga
        Khan Portugal, Agrupamento de Escolas Visconde de Juromenha, Agrupamento de Escolas Leal
        da Câmara, Associação A Comunidade Islâmica da Tapada das Mercês e Mem Martins, Centro
        Social e Paroquial de Algueirão Mem Martins, Associação Juvenil A Ponte, Projeto Orienta.te-
        E8G, Associação para a Promoção do Desenvolvimento Juvenil - APDJ, Associação Criagente,
        Associação Olho Vivo e Associação Ajuda de Mãe.

        Este site foi construído com base na auscultação, participação e opinião dos atores acima
        referidos, sendo alvo de sucessivos testes de utilizador e respetivos ajustes, até a fase atual.
        
        Este projeto foi financiando pelo Programa Contracto Local de Desenvolvimento Social 4G do
        Instituto da Segurança Social e pela Junta de Freguesia de Algueirão Mem Martins, pela União
        das Freguesias de Queluz e Belas e pela Junta de Freguesia de Rio de Mouro.`,
};

interface IIndexProps {
  setSubmittingForm: (e: boolean) => any
}

export function Index({ setSubmittingForm }: IIndexProps) {
  const location2 = useLocation();
  const navigate = useNavigate();

  const goToApply = () => {
    setSubmittingForm(true)
  };

  return (
    <div className={styles.gpsIndexWrapper}>
      <Heading>{texts.title1}</Heading>
      <Text>{texts.descriptionOld}</Text>
      <Heading>{texts.title2}</Heading>
      <TextList>{texts.descriptionOld2}</TextList>
      <Heading>{texts.title4}</Heading>
      <Text>{texts.description4}</Text>
      <Heading>{texts.title3}</Heading>
      <Text>{texts.description2}</Text>
      <ButtonDef onClick={() => goToApply()}>Junte-se a nós!</ButtonDef>
    </div>
  );
}

interface IHeadingProps {
  children: string | JSX.Element;
}

interface ITextProps {
  children: string;
}

export default function Heading({ children }: IHeadingProps) {
  return <h2 className={styles.heading}>{children}</h2>;
}
export function Text({ children }: IHeadingProps) {
  return <p className={styles.paragraph}>{children}</p>;
}

export function TextList({ children }: ITextProps) {
  const steps = children.split(";\n");

  return (
    <ol className={styles.paragraph}>
      {steps.map((step: string, index: number) => (
        <li key={index} className={styles.listItem}>
          {step}
        </li>
      ))}
    </ol>
  );
}
