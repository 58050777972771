import { FormEvent, useRef, useState } from "react";
import { motion } from "framer-motion";

import styles from "../styles/HeroSection.module.css";

import SearchIcon from "@mui/icons-material/Search";
import { useOutsideClick } from "../hooks/useOutsideClick";
import { useKeyPress } from "../hooks/useKeyPress";

interface ISearchBarProps {
    onSearch: (e: any) => any;
    selected?: boolean;
    onClick?: (e: MouseEvent) => any;
}


export function SearchBar({ onSearch
}: ISearchBarProps) {
    const wrapperRef = useRef<HTMLInputElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [state, setState] = useState({
        value: "",
        inputFocus: false,
        inputOpen: false
    })
    useOutsideClick(wrapperRef, () => handleFocusChange(false))
    useKeyPress("Enter", () => handleButtonClick())

    const handleFocusChange = (newState: boolean) => {
        setState(pState => {
            if (newState === true) {
                inputRef.current?.focus()
            }
            if (newState === false) {
                inputRef.current?.blur()
                if (pState.value === "") {
                    return { ...pState, focus: newState, inputOpen: false }
                }
            }
            return { ...pState, focus: newState }

        })
    }

    const handleButtonClick = () => {
        setState((pState) => {
            if (pState.inputOpen === false) {
                inputRef.current?.select()
                return { ...pState, inputOpen: true }
            }
            onSearch(pState.value);
            return pState
        })
    };

    return (
        <div className={`text-white w-screen relative z-10 pt-4 pl-4 pr-4 flex flex-row justify-end gap-2`}>
            {state.inputOpen && (
                <motion.div
                    className={`rounded-xl border-2 min-w-[128px] md:min-w-[256px]`}
                    onClick={() => handleFocusChange(true)}
                    ref={wrapperRef}
                    initial={{
                        borderColor: "white"
                    }}
                    animate={{
                        borderColor: state.inputFocus ? "red" : "white"
                    }}
                    transition={{ duration: 200 }}
                >
                    <input
                        ref={inputRef}
                        className={`bg-transparent h-full w-full pl-2 outline-none`}
                        value={state.value}
                        autoFocus={true}
                        onChange={(e) => setState(ps => ({ ...ps, value: e.target.value }))}
                    />
                </motion.div>
            )}

            <button onClick={handleButtonClick} className={` cursor-pointer border-none bg-transparent`}>
                <SearchBarIcon onClick={() => { }} />
            </button>
        </div>
    );
}


interface ISearchBarIconProps {
    onClick: (e: any) => any
}

function SearchBarIcon({ onClick }: ISearchBarIconProps) {
    const [hover, setHover] = useState(false);

    return (
        <motion.div
            className={`border-2 border-white relative flex-1 shadow-md cursor-pointer overflow-hidden flex items-center justify-center sm:text-lg min-w-24 max-w-48 w-10 py-1 px-1 sm:py-4 sm:px-8 rounded-xl ml-auto`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={onClick}

            initial={{
                color: "white",
                borderColor: "white"
            }}
            whileHover={{
                color: "black",
                borderColor: "white"
            }}
        >
            <motion.div
                className={`absolute top-0 right-0 left-1/2 -translate-x-1/2 bottom-0 rounded-full w-0 h-0`}
                animate={{
                    width: hover ? "300%" : "0%",
                    height: hover ? "inherit" : "0%",
                }}

                style={{ backgroundColor: "white" }}
            />
            <span className={`flex z-10 min-h-fit justify-center items-center`}>
                <SearchIcon />
            </span>
        </motion.div>
    )
}
