import { useEffect } from "react";

export function useKeyPress(key: string, callback: any) {
    useEffect(() => {
        function handlePress(event: KeyboardEvent) {
            if(event.key === key) {
                return callback()
            }
        }

        document.addEventListener("keypress", handlePress);
        return () => {

            document.removeEventListener("keypress", handlePress);
        };
    }, []);

}