import { Button } from "@mui/material"

interface IAddButtonProps {
    onClick: () => any
}

export function AddButton({ onClick }: IAddButtonProps) {
    return (
        <Button variant="contained" type="button" onClick={onClick} style={{backgroundColor: "#004956",}}>
            +
        </Button>
    )
}