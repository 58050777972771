import { useEffect, useState } from 'react';
import styles from '../styles/Footer.module.css';

export function Footer() {

    return (
        <div className={styles.gpsFooterWrapper}>
            <div className={styles.bottomRow}></div>
            <div className={styles.logoRow}>
                <img src='/resources/images/locations/JFAMM.png' />
                <img src='/resources/images/locations/JFQB.jpg' />
                <img src='/resources/images/locations/JFRM.jpg' />
            </div>
        </div>
    )
}
