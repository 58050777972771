import { useEffect, useRef, useState } from "react";
import styles from "../styles/Categories.module.css";
import { motion } from "framer-motion"

const CATEGORY_IMAGE_BASE_URL = "/resources/images/categories/"

const categories = [
  {
    name: "Acesso a computadores e internet",
    slug: "computadores-internet",
    image_name: "Acesso_Computadores_Internet.png",
  },
  {
    name: "Apoio Alimentar",
    slug: "apoio-alimentar",
    image_name: "Apoio_Alimentar.png",
  },
  {
    name: "Apoio ao migrante",
    slug: "apoio-migrante",
    image_name: "Apoio_ao_Migrante.png",
  },
  {
    name: "Apoio Jurídico",
    slug: "apoio-juridico",
    image_name: "Apoio_Juridico.png",
  },
  {
    name: "Apoio nos medicamentos",
    slug: "apoio-nos-medicamentos",
    image_name: "Apoio_aos_Medicamentos.png",
  },
  {
    name: "Apoio nos transportes",
    slug: "apoio-transportes",
    image_name: "Apoio_nos_Transportes.png",
  },
  {
    name: "Apoio Psicológico",
    slug: "apoio-psicologico",
    image_name: "Saude_Mental.png",
  },
  {
    name: "Artes",
    slug: "artes",
    image_name: "Artes.png",
  },
  {
    name: "Atendimento Social",
    slug: "atendimento-social",
    image_name: "Apoio_Social.png",
  },
  {
    name: "Condição de deficiência na infância",
    slug: "deficiencia-infancia",
    image_name: "Condicao_Deficiencia_Infancia.png",
  },
  {
    name: "Condição de deficiência no adulto",
    slug: "deficiencia-adultos",
    image_name: "Condicao_Deficiencia_Adulto.png",
  },
  {
    name: "Desporto",
    slug: "desporto",
    image_name: "Desporto.png",
  },
  {
    name: "Emprego",
    slug: "emprego",
    image_name: "Emprego.png",
  },
  {
    name: "Equipamentos de ajuda à mobilidade, autonomia e conforto",
    slug: "equipamentos-ajuda-mobilidade",
    image_name: "mobilidade-autonomia.jpeg",
  },
  {
    name: "Escolas e equipamentos de infância",
    slug: "escolas-equipamentos-infancia",
    image_name: "Escolas_Equipamentos_Infancia.png",
  },
  {
    name: "Gravidez e 1ª Infância",
    slug: "gravidez-primeira-infancia",
    image_name: "Gravidez_1_Infancia.png",
  },
  {
    name: "Habitação",
    slug: "habitacao",
    image_name: "Habitacao.png",
  },
  {
    name: "Informação geral",
    slug: "informacao-geral",
    image_name: "Informacao_Geral.png",
  },
  {
    name: "Jovens",
    slug: "jovens",
    image_name: "Jovens.png",
  },
  {
    name: "Locais de Culto",
    slug: "locais-culto",
    image_name: "Locais_Culto.png",
  },
  {
    name: "Pagamento de faturas e informações sobre Tarifas Sociais",
    slug: "tarifas",
    image_name: "Pagamento_Faturas_TarifasSociais.png",
  },
  {
    name: "Saúde",
    slug: "saude",
    image_name: "Saude.png",
  },
  {
    name: "Seniores",
    slug: "seniores",
    image_name: "Seniores.png",
  },
  {
    name: "Situação de emergência",
    slug: "emergencia",
    image_name: "Situacao_Emergencia.png",
  },
  {
    name: "Situações de sem abrigo",
    slug: "sem-abrigo",
    image_name: "Situacoes_Sem_Abrigo.png",
  },
  {
    name: "Situações de toxicodependência",
    slug: "toxicodependencia",
    image_name: "Situacao_Toxicodependencia.png",
  },
];

export function getLocationColor(location) {
  const colors = {
    "algueirao-mem-martins": "#0097B2",
    "rio-de-mouro": "#c9ab57",
    "queluz-belas": "#1A9E61",
  }

  return colors[location]
}

export function CategoriesList({ onChange, location, demographic }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(`/api/categories/number`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ location, demographic }),
      });

      if (res.status === 200) {
        const body = await res.json();
        setData(body.categories);
      }
    };

    fetchData();
  }, [location, demographic]);

  return (
    <div className={styles.gps_categories_wrapper}>
      <div className={styles.gps_categories_container}>
        {data &&
          categories.map((c, i) => (
            data.some((e) => e.slug === c.slug && e.count > 0) && (
              <CategoryCard
                count={
                  data.find((e) => e.slug === c.slug && e.count > 0).count
                }
                imageName={data.find((e) => e.slug === c.slug).image_name}
                {...c}
                onClick={onChange}
                borderColor={getLocationColor(location)}
                key={c + i}
              />
            )
          ))}
      </div>
    </div>
  );
}

export function CategoryCard({ name, slug, image_name, onClick, count, borderColor }) {
  const categoryRef = useRef(null);

  const isMobile = window.innerWidth <= 640;

  const handleClick = () => {
    onClick(slug);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // console.log(isMobile)

  return (
    <motion.div
      onClick={handleClick}
      className="cursor-pointer rounded-xl flex relative w-60 h-32 sm:w-64 sm:h-64 border-4 justify-center items-center text-xl sm:text-2xl text-center font-bold"

      initial={{
        borderColor: borderColor,
        color: borderColor,
      }}
      animate={{
        borderColor: borderColor,
        color: borderColor,
      }}
    >
      {/* IMAGE */}
      <motion.div
        style={{
          backgroundImage: `url(${CATEGORY_IMAGE_BASE_URL}${image_name})`,
          opacity: isMobile ? 0.15 : 0.15,
        }}
        className="absolute top-0 right-0 left-0 bottom-0 bg-no-repeat bg-cover bg-center bg-slate-50 "
        initial={{
          filter: `grayscale(1)`
        }}
        whileHover={{
          filter: "grayscale(0.2)",
        }}
      />


      {/* GRADIENT */}
      {/* <motion.div className="absolute top-0 right-0 left-0 bottom-0 pointer-events-none"
        initial={{
          opacity: 0
        }}
        whileHover={{
          opacity: 1,
        }}
        style={{ backgroundColor: borderColor }}
      /> */}

      {/* TEXT */}
      <motion.div
        className="absolute m-auto z-10 p-5 pointer-events-none">
        <span>{name}</span>
      </motion.div>
    </motion.div>
  )


  // return (
  //   <div
  //     ref={categoryRef}
  //     onClick={handleClick}
  //     className={styles.gps_category_wrapper}
  //   >
  //     <div
  //       className={styles.bgImage}
  //       style={{
  //         backgroundImage: `url(/resources/images/categories/${image_name})`,
  //       }}
  //     ></div>
  //     <div className={styles.gradient}></div>
  //     <div className={styles.gps_category_name}>
  //       <span>{name}</span>
  //     </div>
  //     {/* <div className={styles.count}>{count}</div> */}
  //   </div>
  // );
}
