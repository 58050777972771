import React, { useState, useEffect } from "react";
/* import Title from "@components/title" */
import FormInput from "./formInput";
import ButtonDef from "./Button";
import "react-toastify/dist/ReactToastify.css";
import styles from "../styles/ContactForm.module.css";
import { toast, ToastContainer } from "react-toastify";

import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Popover,
  Radio,
  RadioGroup,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import ButtonAsInput from "./ButtonAsInput";
import TextFormInput from "./textInput";
import { categoriesMap } from "./Responses";
import { Text } from "./Index";
import { AddButton } from "./AddButton";
import { RemoveButton } from "./RemoveButton";


interface ContactFormProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}



const ContactForm: React.FC<ContactFormProps> = ({ onSubmit }) => {


  const [name, setName] = useState("");
  const [email, setEmail] = useState([""]);
  const [phone, setPhone] = useState([""]);
  const [address, setAdress] = useState([""]);
  const [georeference, setGeoreference] = useState([""]);
  const [site, setSite] = useState("");
  const [target, setTarget] = useState("");
  const [typology, setTypology] = useState("");
  const [time, setTime] = useState("");
  const [category, setCategory] = useState("");
  const [local, setLocal] = useState([""]);
  const [capacity, setCapacity] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);

  const [description, setDescription] = useState("");
  const [conditions, setConditions] = useState("");
  const [message, setMessage] = useState("");
  /* 
const [isLocalOpen, setIsLocalOpen] = useState(false);
const [isTypologyOpen, setIsTypologyOpen] = useState(false); */

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = {
      name,
      email,
      phone,
      address,
      georeference,
      site,
      time,
      target,
      typology,
      local,
      description,
      conditions,
      message,
      capacity,
      category,
      isUpdate
    };


    if (!name || !email || !description) {
      toast.error("Por favor, preencha todos os campos obrigatórios", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    const res = await fetch("/api/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (res.status === 200) {
      toast.success("Submissão enviada com sucesso", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setName("");
      setEmail([""]);
      setIsUpdate(false);
      setPhone([""]);
      setMessage("");
      setTime("");
      setAdress([""]);
      setGeoreference([""]);
      setSite("");
      setLocal([""]);
      setTarget("");
      setTypology("");
      setDescription("");
      setConditions("");
      setCapacity("");
      setCategory("");
      navigate("/");
    } else if (res.status === 404) {
      const body = await res.json();
      if (body.message === "error") {
        toast.error("Erro. Tente novamente", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };

  const [checkedRM, setCheckedRM] = useState(false);
  const [checkedQB, setCheckedQB] = useState(false);
  const [checkedAMM, setCheckedAMM] = useState(false);

  const handleLocalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const checked = event.target.checked;
    if (value === "Algueirão-Mem Martins") {
      setCheckedAMM(event.target.checked);
    }
    if (value === "Queluz e Belas") {
      setCheckedQB(event.target.checked);
    }
    if (value === "Rio de Mouro") {
      setCheckedRM(event.target.checked);
    }

    if (checked) {
      setLocal((prev) => [...prev, value]);
      console.log("hello1");
      console.log(local);
    } else {
      setLocal((prev) => prev.filter((item) => item !== value));
      console.log("hello2");
      console.log(local);
    }
  };

  const handleChange = (e: any, v: any, i?: any) => {
    if (v === "name") {
      setName(e.target.value);
    }
    if (v === "isUpdate") {
      setIsUpdate(v => !v);
    }
    if (v === "email") {
      setEmail(prev => prev.map((item, index) => index === i ? item = e.target.value : item));
    }
    if (v === "phone") {
      setPhone(prev => prev.map((item, index) => index === i ? item = e.target.value : item));
    }
    if (v === "time") {
      setTime(e.target.value);
    }
    if (v === "message") {
      setMessage(e.target.value);
    }
    if (v === "address") {
      setAdress(prev => prev.map((item, index) => index === i ? item = e.target.value : item));
    }
    if (v === "site") {
      setSite(e.target.value);
    }
    if (v === "target") {
      setTarget(e.target.value);
    }
    if (v === "typology") {
      setTypology(e.target.value);
    }
    if (v === "description") {
      setDescription(e.target.value);
    }
    if (v === "conditions") {
      setConditions(e.target.value);
    }
    if (v === "capacity") {
      setCapacity(e.target.value);
    }
    if (v === "category") {
      setCategory(e.target.value);
    }
    if (v === "georeference") {
      setGeoreference(prev => prev.map((item, index) => index === i ? item = e.target.value : item));
    }
  };

  const [typologyPop, setTypologyPopover] =
    React.useState<HTMLButtonElement | null>(null);

  const [localsPopover, setLocalsPopover] =
    React.useState<HTMLButtonElement | null>(null);

  const handleAddField = (field: any) => {
    if (field === "emails") {
      setEmail((prevValues) => [...prevValues, " "]);
    }
    if (field === "phones") {
      setPhone((prevValues) => [...prevValues, " "]);
    }
    if (field === "addresses") {
      setAdress((prevValues) => [...prevValues, " "]);
    }
    if (field === "georeferences") {
      setGeoreference((prevValues) => [...prevValues, " "]);
    }
    // const previousFieldValue = values[field]
    // setValues({ ...values, [field]: [...previousFieldValue, " "] })
  };
  const handleRemoveField = (field: any) => {
    if (field === "emails") {
      setEmail((prevValues) =>
        prevValues.slice(0, prevValues.length - 1)
      );
    }
    if (field === "phones") {
      setPhone((prevValues) =>
        prevValues.slice(0, prevValues.length - 1)
      );
    }
    if (field === "addresses") {
      setAdress((prevValues) =>
        prevValues.slice(0, prevValues.length - 1)
      );
    }
    if (field === "georeferences") {
      setGeoreference((prevValues) =>
        prevValues.slice(0, prevValues.length - 1)
      );
    }
    // const previousFieldValue = values[field]
    // setValues({ ...values, [field]: previousFieldValue.slice(0, previousFieldValue.length - 1) })
  };

  const open = Boolean(typologyPop);
  const open2 = Boolean(localsPopover);
  const typologies = open ? "simple-popover" : undefined;
  const locals = open2 ? "simple-popover" : undefined;

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () =>
      window.removeEventListener("resize", () =>
        setWidth(window.innerWidth)
      );
  }, []);


  return (
    <>
      <div className={styles.text}>
        <p>
          Sugerimos que coloque uma <b>resposta por descrição</b> (Ex:
          Apoio alimentar e Apoio aos medicamentos seriam duas
          descrições diferentes, e assim sucessivamente). No caso de
          querer acrescentar uma resposta cujo tema/categoria ainda
          não se encontra espelhado no site, por favor contacte a
          Junta de Freguesia de referência.{" "}
          <b>
            É importante a descrição das respostas ser com linguagem
            o mais intuitiva e simples possível, evitando termos
            demasiado técnicos. A Utilização de Siglas beneficia de
            um esclarecimento sobre o que significam.
          </b>
        </p>
      </div>
      {width >= 940 ? (
        <form className={styles.contactForm}>
          <div className={styles.formInputs}>
            <ToastContainer />
            <div className={styles.formLateral}>
              <span className={styles.lateral1}>
                É uma atualização de resposta?
              </span>
              <span className={styles.lateral1}>
                Dados da Entidade:
              </span>
              {/* <span className={styles.info}>
                Para inserir vários dados por campo, separe-os por ";"
              </span> */}
              <div className="h-10"></div>
              <span className={styles.lateral2}>
                Dados da Resposta:
              </span>
              <span className={styles.lateral3}>
                Informações da Resposta:
              </span>
            </div>
            <div className={styles.formText}>
              <FormControl
                sx={{
                  gap: "10px",
                }}
              >
                <div>
                  <Checkbox
                    required
                    id="isUpdated"
                    value={isUpdate}
                    onChange={(e: any) =>
                      handleChange(e, "isUpdate")
                    }
                  />
                </div>
                <TextField
                  required
                  id="nome-entidade"
                  variant="outlined"
                  type="text"
                  label="Nome da entidade"
                  value={name}
                  onChange={(e: any) =>
                    handleChange(e, "name")
                  }
                />
                {name.length === 0 && (
                  <span className={styles.errorMessage}>Obrigatório</span>
                )}

                {email.map((e, i) => (
                  <>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                      key={i}
                    >
                      {i === email.length - 1 &&
                        i !== 0 && (
                          <RemoveButton
                            onClick={() =>
                              handleRemoveField(
                                "emails"
                              )
                            }
                          />
                        )}
                      <TextField
                        required
                        // onFocus={() => setFieldTouched("emails")}
                        // onChange={handleChange}
                        onChange={(e: any) =>
                          handleChange(e, "email", i)
                        }
                        name={`emails.${i}`}
                        value={email[i]}
                        label="Email"
                        variant="outlined"
                      />
                      {i === email.length - 1 && (
                        <AddButton
                          onClick={() =>
                            handleAddField("emails")
                          }
                        />
                      )}
                    </div>
                    {/* <span className={styles.errorMessage}><ErrorMessage name={`emails.${i}`} /></span> */}
                  </>
                ))}
                {email.length >= 1 && email.every(e => e.length === 0) && (
                  <span className={styles.errorMessage}>Obrigatório</span>
                )}

                {/* <TextField
                                    id="telefone-entidade"
                                    variant="outlined"
                                    type="text"
                                    label="Telefone"
                                    value={phone}
                                    onChange={(e: any) =>
                                        handleChange(e, "phone")
                                    }
                                /> */}
                {phone.map((e, i) => (
                  <>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                      key={i}
                    >
                      {i === phone.length - 1 &&
                        i !== 0 && (
                          <RemoveButton
                            onClick={() =>
                              handleRemoveField(
                                "phones"
                              )
                            }
                          />
                        )}
                      <TextField
                        // onFocus={() => setFieldTouched("phones")}
                        // onChange={handleChange}
                        onChange={(e: any) =>
                          handleChange(e, "phone", i)
                        }
                        name={`phones.${i}`}
                        value={phone[i]}
                        label="Telefone"
                        variant="outlined"
                      />
                      {i === phone.length - 1 && (
                        <AddButton
                          onClick={() =>
                            handleAddField("phones")
                          }
                        />
                      )}
                    </div>
                    {/* <span className={styles.errorMessage}><ErrorMessage name={`emails.${i}`} /></span> */}
                  </>
                ))}
                {/* <TextField
                                    id="morada-entidade"
                                    variant="outlined"
                                    type="text"
                                    label="Morada"
                                    value={address}
                                    onChange={(e: any) =>
                                        handleChange(e, "address")
                                    }
                                /> */}
                {address.map((e, i) => (
                  <>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                      key={i}
                    >
                      {i === address.length - 1 &&
                        i !== 0 && (
                          <RemoveButton
                            onClick={() =>
                              handleRemoveField(
                                "addresses"
                              )
                            }
                          />
                        )}
                      <TextField
                        // onFocus={() => setFieldTouched("addresss")}
                        // onChange={handleChange}
                        onChange={(e: any) =>
                          handleChange(e, "address", i)
                        }
                        name={`addresses.${i}`}
                        value={address[i]}
                        label="Morada"
                        variant="outlined"
                      />
                      {i === address.length - 1 && (
                        <AddButton
                          onClick={() =>
                            handleAddField("addresses")
                          }
                        />
                      )}
                    </div>
                    {/* <span className={styles.errorMessage}><ErrorMessage name={`emails.${i}`} /></span> */}
                  </>
                ))}
                {/* <TextField
                                    id="georeference-entidade"
                                    variant="outlined"
                                    type="text"
                                    label="Georreferenciação"
                                    placeholder="38.806633820396776, -9.308083662374372"
                                    value={georeference}
                                    onChange={(e: any) =>
                                        handleChange(e, "georeference")
                                    }
                                /> */}
                {georeference.map((e, i) => (
                  <>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                      key={i}
                    >
                      {i === georeference.length - 1 &&
                        i !== 0 && (
                          <RemoveButton
                            onClick={() =>
                              handleRemoveField(
                                "georeferences"
                              )
                            }
                          />
                        )}
                      <TextField
                        // onFocus={() => setFieldTouched("georeferences")}
                        // onChange={handleChange}
                        onChange={(e: any) =>
                          handleChange(e, "georeference", i)
                        }
                        name={`georeferences.${i}`}
                        value={georeference[i]}
                        label="Georreferenciação"
                        variant="outlined"
                        placeholder="38.806633820396776, -9.308083662374372"
                      />
                      {i === georeference.length - 1 && (
                        <AddButton
                          onClick={() =>
                            handleAddField("georeferences")
                          }
                        />
                      )}
                    </div>
                    {/* <span className={styles.errorMessage}><ErrorMessage name={`emails.${i}`} /></span> */}
                  </>
                ))}
                <TextField
                  variant="outlined"
                  id="website-entidade"
                  type="text"
                  label="Website"
                  value={site}
                  onChange={(e: any) =>
                    handleChange(e, "site")
                  }
                />
                <TextField
                  id="horario-entidade"
                  variant="outlined"
                  type="text"
                  label="Horário"
                  placeholder="ex: Segunda a Sexta das 8h10 - 23h45"
                  value={time}
                  onChange={(e: any) =>
                    handleChange(e, "time")
                  }
                />
                <div className="h-10"></div>
                <TextField
                  id="capacidade-resposta"
                  variant="outlined"
                  type="text"
                  label="Capacidade da Resposta"
                  inputProps={{ maxLength: 500 }}
                  placeholder="Máximo 500 caracteres"
                  value={capacity}
                  onChange={(e: any) =>
                    handleChange(e, "capacity")
                  }
                />
                <TextField
                  id="publico-alvo"
                  variant="outlined"
                  type="text"
                  label="Público alvo"
                  value={target}
                  onChange={(e: any) =>
                    handleChange(e, "target")
                  }
                />
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <InputLabel id="categoria-label">
                    Categoria
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="categoria-label"
                    id="categoria"
                    value={category}
                    label="Categoria"
                    onChange={(e: any) =>
                      handleChange(e, "category")
                    }
                  >
                    {categoriesMap.map((c) => (
                      <MenuItem value={c.slug}>
                        {c.value}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </FormControl>
              <span className={styles.title}>Tipologia:</span>
              <FormControl>
                <RadioGroup
                  aria-labelledby={typologies}
                  name="radio-buttons-group"
                  onChange={(e: any) =>
                    handleChange(e, "typology")
                  }
                  defaultValue={typology}
                >
                  <FormControlLabel
                    value="social"
                    control={<Radio />}
                    label="Solidária"
                  />
                  <FormControlLabel
                    value="public"
                    control={<Radio />}
                    label="Pública"
                  />
                  <FormControlLabel
                    value="private"
                    control={<Radio />}
                    label="Privada"
                  />
                </RadioGroup>
              </FormControl>
              <span className={styles.title}>
                Freguesias em que atua:
              </span>
              <FormControl>
                <RadioGroup
                  aria-labelledby={locals}
                  name="radio-buttons-group2"
                >
                  <FormControlLabel
                    value="Algueirão-Mem Martins"
                    control={
                      <Checkbox
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleLocalChange(e)}
                        checked={checkedAMM}
                      />
                    }
                    label="Algueirão-Mem Martins"
                  />
                  <FormControlLabel
                    value="Queluz e Belas"
                    control={
                      <Checkbox
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleLocalChange(e)}
                        checked={checkedQB}
                      />
                    }
                    label="Queluz e Belas"
                  />
                  <FormControlLabel
                    value="Rio de Mouro"
                    control={
                      <Checkbox
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleLocalChange(e)}
                        checked={checkedRM}
                      />
                    }
                    label="Rio de Mouro"
                  />
                </RadioGroup>
              </FormControl>
              {!checkedRM && !checkedAMM && !checkedQB && (
                <span className={styles.errorMessage}>Obrigatório</span>
              )}
              <div className="h-10"></div>
              <TextField
                required
                id="descricao-resposta"
                multiline
                minRows={4}
                variant="outlined"
                type="textarea"
                label="Descrição da Resposta"
                inputProps={{ maxLength: 1000 }}
                placeholder="Máximo 1000 caracteres"
                value={description}
                onChange={(e: any) =>
                  handleChange(e, "description")
                }
              />
              {description.length === 0 && (
                <span className={styles.errorMessage}>Obrigatório</span>
              )}
              <TextField
                id="condicoes-acesso"
                multiline
                minRows={4}
                variant="outlined"
                type="textarea"
                label="Condições de Acesso"
                placeholder="Máximo 1000 caracteres"
                inputProps={{ maxLength: 1000 }}
                value={conditions}
                onChange={(e: any) =>
                  handleChange(e, "conditions")
                }
              />
            </div>
          </div>
          <div className={styles.button}>
            <ButtonDef
              disabled={
                description.length === 0 ||
                email.every(e => e.length === 0) ||
                (!checkedAMM && !checkedQB && !checkedRM) ||
                name.length === 0
              }
              onClick={handleSubmit}>
              Submeter formulário
            </ButtonDef>
          </div>
        </form>
      ) : (
        <form className={styles.contactForm}>
          <div className={styles.formInputs}>
            <ToastContainer />
            <div className={styles.formText}>
              <span className={styles.lateral1}>
                Dados da Entidade:
              </span>
              <FormControl
                sx={{
                  gap: "10px",
                }}
              >
                <TextField
                  id="nome-entidade"
                  variant="outlined"
                  type="text"
                  label="Nome da entidade"
                  value={name}
                  onChange={(e: any) =>
                    handleChange(e, "name")
                  }
                />
                {name && name.length === 1 && (
                  <span className={styles.errorMessage}>Obrigatório</span>
                )}

                {email.map((e, i) => (
                  <>
                    <div
                      style={{ display: "flex", gap: "0.75rem" }}
                      key={i}
                    >
                      {i === email.length - 1 &&
                        i !== 0 && (
                          <RemoveButton
                            onClick={() =>
                              handleRemoveField(
                                "emails"
                              )
                            }
                          />
                        )}
                      <TextField
                        required
                        // onFocus={() => setFieldTouched("emails")}
                        // onChange={handleChange}
                        onChange={(e: any) =>
                          handleChange(e, "email", i)
                        }
                        name={`emails.${i}`}
                        value={email[i]}
                        label="Email"
                        variant="outlined"
                      />
                      {i === email.length - 1 && (
                        <AddButton
                          onClick={() =>
                            handleAddField("emails")
                          }
                        />
                      )}
                    </div>
                    {/* <span className={styles.errorMessage}><ErrorMessage name={`emails.${i}`} /></span> */}
                  </>
                ))}
                {email.length >= 1 && email.every(e => e.length === 0) && (
                  <span className={styles.errorMessage}>Obrigatório</span>
                )}

                {phone.map((e, i) => (
                  <>
                    <div
                      style={{ display: "flex", gap: "0.75rem" }}
                      key={i}
                    >
                      {i === phone.length - 1 &&
                        i !== 0 && (
                          <RemoveButton
                            onClick={() =>
                              handleRemoveField(
                                "phones"
                              )
                            }
                          />
                        )}
                      <TextField
                        // onFocus={() => setFieldTouched("phones")}
                        // onChange={handleChange}
                        onChange={(e: any) =>
                          handleChange(e, "phone", i)
                        }
                        name={`phones.${i}`}
                        value={phone[i]}
                        label="Telefone"
                        variant="outlined"
                      />
                      {i === phone.length - 1 && (
                        <AddButton
                          onClick={() =>
                            handleAddField("phones")
                          }
                        />
                      )}
                    </div>
                    {/* <span className={styles.errorMessage}><ErrorMessage name={`emails.${i}`} /></span> */}
                  </>
                ))}
                {address.map((e, i) => (
                  <>
                    <div
                      style={{ display: "flex", gap: "0.75rem" }}
                      key={i}
                    >
                      {i === address.length - 1 &&
                        i !== 0 && (
                          <RemoveButton
                            onClick={() =>
                              handleRemoveField(
                                "addresses"
                              )
                            }
                          />
                        )}
                      <TextField
                        // onFocus={() => setFieldTouched("addresss")}
                        // onChange={handleChange}
                        onChange={(e: any) =>
                          handleChange(e, "address", i)
                        }
                        name={`addresses.${i}`}
                        value={address[i]}
                        label="Morada"
                        variant="outlined"
                      />
                      {i === address.length - 1 && (
                        <AddButton
                          onClick={() =>
                            handleAddField("addresses")
                          }
                        />
                      )}
                    </div>
                    {/* <span className={styles.errorMessage}><ErrorMessage name={`emails.${i}`} /></span> */}
                  </>
                ))}
                {georeference.map((e, i) => (
                  <>
                    <div
                      style={{ display: "flex", gap: "0.75rem" }}
                      key={i}
                    >
                      {i === georeference.length - 1 &&
                        i !== 0 && (
                          <RemoveButton
                            onClick={() =>
                              handleRemoveField(
                                "georeferences"
                              )
                            }
                          />
                        )}
                      <TextField
                        // onFocus={() => setFieldTouched("georeferences")}
                        // onChange={handleChange}
                        onChange={(e: any) =>
                          handleChange(e, "georeference", i)
                        }
                        name={`georeferences.${i}`}
                        value={georeference[i]}
                        label="Georreferenciação"
                        variant="outlined"
                        placeholder="38.806633820396776, -9.308083662374372"
                      />
                      {i === georeference.length - 1 && (
                        <AddButton
                          onClick={() =>
                            handleAddField("georeferences")
                          }
                        />
                      )}
                    </div>
                    {/* <span className={styles.errorMessage}><ErrorMessage name={`emails.${i}`} /></span> */}
                  </>
                ))}

                <TextField
                  variant="outlined"
                  id="website-entidade"
                  type="text"
                  label="Website"
                  value={site}
                  onChange={(e: any) =>
                    handleChange(e, "site")
                  }
                />
                <span className={styles.lateral2}>
                  Dados da Resposta:
                </span>
                <TextField
                  id="horario-entidade"
                  variant="outlined"
                  type="text"
                  label="Horário"
                  placeholder="ex: Segunda a Sexta das 8h10 - 23h45"
                  value={time}
                  onChange={(e: any) =>
                    handleChange(e, "time")
                  }
                />
                <TextField
                  id="capacidade-resposta"
                  variant="outlined"
                  type="text"
                  label="Capacidade da Resposta"
                  value={capacity}
                  onChange={(e: any) =>
                    handleChange(e, "capacity")
                  }
                />
                <TextField
                  id="publico-alvo"
                  variant="outlined"
                  type="text"
                  label="Público alvo"
                  value={target}
                  onChange={(e: any) =>
                    handleChange(e, "target")
                  }
                />
              </FormControl>
              <span className={styles.title}>Tipologia: *</span>
              <FormControl>
                <RadioGroup
                  aria-labelledby={typologies}
                  name="radio-buttons-group"
                  onChange={(e: any) =>
                    handleChange(e, "typology")
                  }
                  defaultValue={typology}
                >
                  <FormControlLabel
                    value="social"
                    control={<Radio />}
                    label="Solidária"
                  />
                  <FormControlLabel
                    value="public"
                    control={<Radio />}
                    label="Pública"
                  />
                  <FormControlLabel
                    value="private"
                    control={<Radio />}
                    label="Privada"
                  />
                </RadioGroup>
              </FormControl>
              <span className={styles.title}>
                Freguesias em que atua: *
              </span>
              <FormControl>
                <RadioGroup
                  aria-labelledby={locals}
                  name="radio-buttons-group2"
                >
                  <FormControlLabel
                    value="Algueirão-Mem Martins"
                    control={
                      <Checkbox
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleLocalChange(e)}
                        checked={checkedAMM}
                      />
                    }
                    label="Algueirão-Mem Martins"
                  />
                  <FormControlLabel
                    value="Queluz e Belas"
                    control={
                      <Checkbox
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleLocalChange(e)}
                        checked={checkedQB}
                      />
                    }
                    label="Queluz e Belas"
                  />
                  <FormControlLabel
                    value="Rio de Mouro"
                    control={
                      <Checkbox
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ) => handleLocalChange(e)}
                        checked={checkedRM}
                      />
                    }
                    label="Rio de Mouro"
                  />
                </RadioGroup>
              </FormControl>
              {!checkedRM && !checkedAMM && !checkedQB && (
                <span className={styles.errorMessage}>Obrigatório</span>
              )}
              <span className={styles.lateral3}>
                Informações da Resposta:
              </span>
              <TextField
                id="descricao-resposta"
                multiline
                minRows={4}
                variant="outlined"
                type="textarea"
                label="Descrição da Resposta"
                inputProps={{ maxLength: 600 }}
                placeholder="Máximo 600 caracteres"
                value={description}
                onChange={(e: any) =>
                  handleChange(e, "description")
                }
              />
              {(description.length === 0) && (
                <span className={styles.errorMessage}>Obrigatório</span>
              )}
              <TextField
                id="condicoes-acesso"
                multiline
                minRows={4}
                variant="outlined"
                type="textarea"
                label="Condições de Acesso"
                placeholder="Máximo 300 caracteres"
                inputProps={{ maxLength: 600 }}
                value={conditions}
                onChange={(e: any) =>
                  handleChange(e, "conditions")
                }
              />
            </div>
          </div>
          <div className={styles.button}>
            <ButtonDef
              disabled={
                description.length === 0 ||
                email.every(e => e.length === 0) ||
                (!checkedAMM && !checkedQB && !checkedRM) ||
                name.length === 0
              }
              onClick={handleSubmit}>
              Submeter formulário
            </ButtonDef>
          </div>
        </form>
      )}
    </>
  );
};

//TODO: Verificar o submit

const contacts = [{ label: "email@email.com" }];

const ContactUs: React.FC = () => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () =>
      window.removeEventListener("resize", () =>
        setWidth(window.innerWidth)
      );
  }, []);

  return (
    <>
      <div className="p-20 max-w-3xl">
        <div className={styles.formWrapper}>
          <ContactForm onSubmit={(event) => event.preventDefault()} />
        </div>
      </div>
    </>
  );
};

export function ContactRequest() {
  const amm_email = "geral@jfamm.pt";
  const qlb_email = "acaosocial@ufqueluzbelas.pt";
  const rdm_email = "gps@jf-riodemouro.pt";
  return (
    <div className={styles.contactRequestWrapper}>
      <a href={`mailto:${amm_email}`} target="_blank" rel="noreferrer">
        <span className={styles.logo}>Algueirão-Mem Martins</span>
      </a>
      <a href={`mailto:${qlb_email}`} target="_blank" rel="noreferrer">
        <span className={styles.logo}>Queluz e Belas</span>
      </a>
      <a href={`mailto:${rdm_email}`} target="_blank" rel="noreferrer">
        <span className={styles.logo}>Rio de Mouro</span>
      </a>
    </div>
  );
}

export { ContactUs };
