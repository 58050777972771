import styles from "../styles/Responses.module.css";
import "font-awesome/css/font-awesome.min.css";
import { useEffect, useState } from "react";
import { BreadCrumbs } from "./HeroSection";

export const categoriesMap = [
  //Categories
  {
    value: "Apoio Jurídico",
    slug: "apoio-juridico",
  },
  {
    value: "Atendimento Social",
    slug: "atendimento-social",
  },
  {
    value: "Acesso a computadores e internet",
    slug: "computadores-internet",
  },
  {
    value: "Apoio Psicológico",
    slug: "apoio-psicologico",
  },
  {
    value: "Saúde",
    slug: "saude",
  },
  {
    value: "Apoio nos medicamentos",
    slug: "apoio-nos-medicamentos",
  },
  {
    value: "Apoio Alimentar",
    slug: "apoio-alimentar",
  },
  {
    value: "Pagamento de faturas e informações sobre Tarifas Sociais",
    slug: "tarifas",
  },
  {
    value: "Informação geral",
    slug: "informacao-geral",
  },
  {
    value: "Escolas e equipamentos de infância",
    slug: "escolas-equipamentos-infancia",
  },
  {
    value: "Jovens",
    slug: "jovens",
  },
  {
    value: "Equipamentos de ajuda à mobilidade, autonomia e conforto",
    slug: "equipamentos-ajuda-mobilidade",
  },
  {
    value: "Apoio ao migrante",
    slug: "apoio-migrante",
  },
  {
    value: "Emprego",
    slug: "emprego",
  },
  {
    value: "Desporto",
    slug: "desporto",
  },
  {
    value: "Artes",
    slug: "artes",
  },
  {
    value: "Situações de sem abrigo",
    slug: "sem-abrigo",
  },
  {
    value: "Condição de deficiência na infância",
    slug: "deficiencia-infancia",
  },
  {
    value: "Condição de deficiência no adulto",
    slug: "deficiencia-adultos",
  },
  {
    value: "Situações de toxicodependência",
    slug: "toxicodependencia",
  },
  {
    value: "Habitação",
    slug: "habitacao",
  },
  {
    value: "Locais de Culto",
    slug: "locais-culto",
  },
  {
    value: "Gravidez e 1ª Infância",
    slug: "gravidez-primeira-Infancia",
  },
  {
    value: "Apoio nos transportes",
    slug: "apoio-transportes",
  },
  {
    value: "Seniores",
    slug: "seniores",
  },
  {
    value: "Situação de emergência",
    slug: "emergencia",
  },
];

export function desluggify(slug) {
  const map = [
    //Locations
    { slug: "algueirao-mem-martins", value: "Algueirão-Mem Martins" },
    { slug: "rio-de-mouro", value: "Rio de Mouro" },
    { slug: "queluz-belas", value: "Queluz e Belas" },

    //Categories
    {
      value: "Apoio Jurídico",
      slug: "apoio-juridico",
    },
    {
      value: "Atendimento Social",
      slug: "atendimento-social",
    },
    {
      value: "Acesso a computadores e internet",
      slug: "computadores-internet",
    },
    {
      value: "Apoio Psicológico",
      slug: "apoio-psicologico",
    },
    {
      value: "Saúde",
      slug: "saude",
    },
    {
      value: "Apoio nos medicamentos",
      slug: "apoio-nos-medicamentos",
    },
    {
      value: "Apoio Alimentar",
      slug: "apoio-alimentar",
    },
    {
      value: "Pagamento de faturas e informações sobre Tarifas Sociais",
      slug: "tarifas",
    },
    {
      value: "Informação geral",
      slug: "informacao-geral",
    },
    {
      value: "Escolas e equipamentos de infância",
      slug: "escolas-equipamentos-infancia",
    },
    {
      value: "Jovens",
      slug: "jovens",
    },
    {
      value: "Equipamentos de ajuda à mobilidade, autonomia e conforto",
      slug: "equipamentos-ajuda-mobilidade",
    },
    {
      value: "Apoio ao migrante",
      slug: "apoio-migrante",
    },
    {
      value: "Emprego",
      slug: "emprego",
    },
    {
      value: "Desporto",
      slug: "desporto",
    },
    {
      value: "Artes",
      slug: "artes",
    },
    {
      value: "Situações de sem abrigo",
      slug: "sem-abrigo",
    },
    {
      value: "Condição de deficiência na infância",
      slug: "deficiencia-infancia",
    },
    {
      value: "Condição de deficiência no adulto",
      slug: "deficiencia-adultos",
    },
    {
      value: "Situações de toxicodependência",
      slug: "toxicodependencia",
    },
    {
      value: "Habitação",
      slug: "habitacao",
    },
    {
      value: "Locais de Culto",
      slug: "locais-culto",
    },
    {
      value: "Gravidez e 1ª Infância",
      slug: "gravidez-primeira-Infancia",
    },
    {
      value: "Apoio nos transportes",
      slug: "apoio-transportes",
    },
    {
      value: "Seniores",
      slug: "seniores",
    },
    {
      value: "Situação de emergência",
      slug: "emergencia",
    },

    //Demographics
    { value: "Crianças", slug: "children" },
    { value: "Jovens", slug: "youth" },
    { value: "Famílias", slug: "families" },
    { value: "Seniores", slug: "seniors" },
    { value: "Deficiência", slug: "deficiency" },
    { value: "Educação", slug: "education" },
    { value: "Emprego", slug: "work" },
    { value: "Apoios Sociais", slug: "social-service" },
    { value: "Migração", slug: "migration" },
    { value: "Saúde", slug: "health" },
    { value: "Emergência", slug: "emergency" },

    //Entidades
    {
      value:
        "AFAPS-SM - Associação Família como Parceiro Social de Serra das Minas",
      slug: "1",
    },
    { value: "O Palhaço Alegre - Jardim de Infância Lda", slug: "2" },
    {
      value:
        '"Agir Mais - E8G\nEntidade promotora e Gestora: Associação Lusocaboverdena de Sintra - ACAS"',
      slug: "3",
    },
    { value: "Agrupamento de Escolas de Algueirão", slug: "4" },
    { value: "Agrupamento de Escolas de Mem Martins", slug: "5" },
    {
      value: "Agrupamento de Escolas Escultor Francisco dos Santos",
      slug: "6",
    },
    { value: "Agrupamento de Escolas Ferreira de Castro", slug: "7" },
    { value: "Agrupamento de Escolas Leal da Câmara", slug: "8" },
    { value: "Agrupamento de Escolas Visconde de Juromenha", slug: "9" },
    { value: "Ajuda de Mãe", slug: "10" },
    { value: "APAV (Associação Portuguesa de Apoio à Vítima)", slug: "11" },
    {
      value:
        "APD - Associação Portuguesa de Deficientes Delegação Local de Sintra",
      slug: "12",
    },
    {
      value: "APDJ - Associação para a Promoção do Desenvolvimento Juvenil",
      slug: "13",
    },
    {
      value: "ARCBT — Associação de Recreio e Cultura do Bairro da Tabaqueira",
      slug: "14",
    },
    {
      value:
        "Associação A Comunidade Islâmica da Tapada das Mercês e Mem Martins",
      slug: "15",
    },
    {
      value:
        "Associação CIAPA Centro Aeroespacial: Educação para o Desenvolvimento Integral e Inclusão",
      slug: "16",
    },
    {
      value:
        "Associação de Pais e Encarregados de Educação da EB Nº 2 da Serra das Minas",
      slug: "17",
    },
    {
      value:
        "Associação de Pais e Encarregados de Educação EB1 Rio de Mouro nº2",
      slug: "18",
    },
    { value: "Associação Empresarial de Sintra", slug: "19" },
    { value: "Associação Juvenil Ponte", slug: "20" },
    { value: "Associação Luso Caboverdeana de Sintra", slug: "21" },
    { value: "Associação Olho Vivo", slug: "22" },
    { value: "Associação PARTILHAR VIDA", slug: "23" },
    { value: "Associação Quinta Essência", slug: "24" },
    {
      value:
        "Be More Solidário - Associação de Apoio aos Sem Abrigo e Famílias Carenciadas",
      slug: "25",
    },
    { value: "Biblioteca Ruy Belo", slug: "26" },
    { value: "Bombeiros", slug: "27" },
    { value: "Bynd Senior Residences, LDA", slug: "28" },
    { value: "Câmara Municipal de Sintra", slug: "29" },
    { value: "Casa de Sant'Ana", slug: "30" },
    { value: "Casa de Saúde da ldanha IIHSCJ", slug: "31" },
    { value: "CBESQ - Centro de Bem-Estar Social de Queluz", slug: "32" },
    { value: "Centro Comunitário e Paroquial de Rio de Mouro", slug: "33" },
    {
      value: "Centro de Formação profissional da Aldeia de Santa Isabel",
      slug: "34",
    },
    { value: "Centro Social da Sagrada Família", slug: "35" },
    { value: "Centro Social de Reformados e Idosos de Albarraque", slug: "36" },
    {
      value: "Centro Social Paroquial Algueirão Mem Martins Mercês",
      slug: "37",
    },
    { value: "CERCITOP, CRL", slug: "38" },
    {
      value:
        '"CiNTRA - Centro Integrado de Tratamento e Reabilitação em Ambulatório em Sintra\nCHPL - Centro Hospitalar Psiquiátrico de Lisboa"',
      slug: "39",
    },
    { value: "Círculos Arte-Terapia", slug: "40" },
    { value: "Colégio dos Plátanos", slug: "41" },
    { value: "Cooperativa Miminhos Alegres", slug: "42" },
    { value: "Creche Popular de Rio de Mouro", slug: "43" },
    {
      value:
        "Escola Básica 1º ciclo (1º ao 4º ano) com Jardim de Infância do Algueirão",
      slug: "44",
    },
    { value: "Escola Básica 1º ciclo Algueirão", slug: "45" },
    {
      value: "Escola Básica 1º ciclo com Jardim de Infância Casal da Cavaleira",
      slug: "46",
    },
    {
      value: "Escola Básica 1º Ciclo e Jardim de Infância de Mem Martins",
      slug: "47",
    },
    {
      value: "Escola Básica 1º Ciclo e Jardim de Infância de Ouressa",
      slug: "48",
    },
    { value: "Escola Básica 1º Ciclo Nº1 de Mem Martins", slug: "49" },
    { value: "Escola Básica 1º Ciclo Nº3 de Mem Martins", slug: "50" },
    {
      value: "Escola EB1/JI Eduardo Luna de Carvalho - Castelinhos",
      slug: "51",
    },
    { value: "Escola EB1/JI Tapada das Mercês - Bandeirinhas", slug: "52" },
    { value: "Espaço Pendão em Movimento", slug: "53" },
    { value: "Externato Afonso de Albuquerque", slug: "54" },
    { value: "Grupo de Escoteiros 178 das Mercês", slug: "55" },
    {
      value:
        '"Hospital Psiquiátrico\nInstituto S. João de Deus - Casa de Saúde do Telhal"',
      slug: "56",
    },
    { value: "Jangada D'Emoções", slug: "57" },
    { value: 'Jardim de Infância "Escola do Povo das Mercês"', slug: "58" },
    { value: "Jardim de Infância de Mem Martins Nº2", slug: "59" },
    { value: "Jardim Escola João de Deus de Albarraque", slug: "60" },
    {
      value: "Junta de Freguesia da União das Freguesias de Queluz e Belas",
      slug: "61",
    },
    { value: "Junta de Freguesia de Algueirão Mem Martins", slug: "62" },
    { value: "Junta de Freguesia de Rio de Mouro", slug: "63" },
    {
      value:
        '"KS Escolhas\nNome da Entidade Promotora: Junta de Freguesia de Algueirão Mem Martins\nEntidade Promotora: Associação Luso Caboverdeana de Sintra"',
      slug: "64",
    },
    { value: "Linha de Emergência da Segurança Social", slug: "65" },
    { value: "Linha de Emergência Nacional", slug: "66" },
    { value: "Ludoteca - Crescer a Brincar", slug: "67" },
    {
      value:
        '"Orienta.te- E8G\nEntidade Gestora: Junta de Freguesia de Rio de Mouro\nEntidade Promotora: Associação Juvenil Ponte"',
      slug: "68",
    },
    { value: "Portal da Habitação", slug: "69" },
    {
      value: "PSP (Policia Segurança Pública) Esquadra de Mem Martins",
      slug: "70",
    },
    { value: "PSP (Policia Segurança Pública) Esquadra de Queluz", slug: "71" },
    {
      value: "PSP (Policia Segurança Pública) Esquadra de Rio de Mouro",
      slug: "72",
    },
    { value: "Requinte das Palavras, Unipessoal, Lda", slug: "73" },
    { value: "Santa Casa da Misericórdia de Sintra", slug: "74" },
    { value: "SEAGENCY, CRL e Câmara Municipal de Sintra", slug: "75" },
    { value: "SMAS Sintra", slug: "76" },
    {
      value:
        "URPITMA — União de Reformados, Pensionistas e Idosos de Tala-Meleças",
      slug: "77",
    },
    { value: "Associação Juvenil Ponte", slug: "78" },
    { value: "Associação Juvenil Ponte", slug: "79" },
    { value: "Associação Juvenil Ponte", slug: "80" },
    { value: "Associação Juvenil Ponte", slug: "81" },
    { value: "Associação Juvenil Ponte", slug: "82" },
    { value: "Associação Juvenil Ponte", slug: "83" },
    { value: "CERCITOP, CRL", slug: "84" },
    { value: "CERCITOP, CRL", slug: "85" },
    { value: "Arte Terapia", slug: "86" },
    { value: "Câmara Municipal de Sintra e Santa Casa da Misericórdia de Sintra", slug: "87" },
  ];
  const value = map.find((e) => e.slug === slug)?.value;

  return value ?? slug;
}

export function imageFindBySlug(slug) {
  const map = [
    { image: `url("/resources/images/responses/1afaps.jpg")`, slug: "1" },
    {
      image: `url("/resources/images/responses/2o_palhaco_alegre.jpg")`,
      slug: "2",
    },
    { image: `url("/resources/images/responses/3agir_mais.jpg")`, slug: "3" },
    {
      image: `url("/resources/images/responses/4agrupamento_escolas_algueirao.jpg")`,
      slug: "4",
    },
    {
      image: `url("/resources/images/responses/5agrupamento_de_escolas_de_mem_martins_escola_secundaria_de_Mem_Martins.jpg")`,
      slug: "5",
    },
    {
      image: `url("/resources/images/responses/6agrupamento_escolas_escultor_francisco_dos_santos.jpg")`,
      slug: "6",
    },
    {
      image: `url("/resources/images/responses/7agrupamento_escolas_ferreira_castro.jpg")`,
      slug: "7",
    },
    {
      image: `url("/resources/images/responses/8agrupamento_de_escolas_leal_da_camara.jpg")`,
      slug: "8",
    },
    {
      image: `url("/resources/images/responses/9agrupamento_escolas_visconde_juromenha.jpg")`,
      slug: "9",
    },
    {
      image: `url("/resources/images/responses/10ajuda_de_mae.jpg")`,
      slug: "10",
    },
    {
      image: `url("/resources/images/responses/11apav.jpg")`,
      slug: "11",
    },
    {
      image: `url("/resources/images/responses/12associacao_portuguesa_de_deficientes.jpg")`,
      slug: "12",
    },
    { image: `url("/resources/images/responses/13APDJ.jpg")`, slug: "13" },
    {
      image: `url("/resources/images/responses/14ARCBT_Associacao_de_recreio_e_cultura_do_bairro_da_tabaqueira.jpg")`,
      slug: "14",
    },
    {
      image: `url("/resources/images/responses/15Associacao_a_comunidade_Islamica_da_tapada_das_merces_e_mem-martins.jpg")`,
      slug: "15",
    },
    { image: `url("/resources/images/responses/16CIAPA.jpg")`, slug: "16" },
    /* { image: `url("/resources/images/responses/.jpg")`, slug: "17" }, */
    /* { image: `url("/resources/images/responses/.jpg")`, slug: "18" }, */
    {
      image: `url("/resources/images/responses/19associacao_empresarial_de_sintra.jpg")`,
      slug: "19",
    },
    {
      image: `url("/resources/images/responses/20associacao_juvenil_ponte.jpg")`,
      slug: "20",
    },
    { image: `url("/resources/images/responses/21ACAS.jpg")`, slug: "21" },
    /* { image: `url("/resources/images/responses/.jpg")`, slug: "22" }, */
    {
      image: `url("/resources/images/responses/23Associacao_Partilhar_Vida.jpg")`,
      slug: "23",
    },
    // {
    //   image: `url("/resources/images/responses/24EscolasAlgueirao.jpg")`,
    //   slug: "24",
    // },
    {
      image: `url("/resources/images/responses/25be_more_solidario.jpg")`,
      slug: "25",
    },
    // {
    //   image: `url("/resources/images/responses/26AgrupamentoFerreiraCastro.jpg")`,
    //   slug: "26",
    // },
    {
      image: `url("/resources/images/responses/27bombeiros.jpg")`,
      slug: "27",
    },
    {
      image: `url("/resources/images/responses/28Bynd_Senior_Residences.jpg")`,
      slug: "28",
    },
    {
      image: `url("/resources/images/responses/29camara_municipal_de_sintra.jpg")`,
      slug: "29",
    },
    /* { image: `url("/resources/images/responses/.jpg")`, slug: "30" }, */
    {
      image: `url("/resources/images/responses/31Casa_Saude_Idanha.jpg")`,
      slug: "31",
    },
    { image: `url("/resources/images/responses/32CBESQ.jpg")`, slug: "32" },
    {
      image: `url("/resources/images/responses/33Centro_Comunitario_e_Paroquial_de_Rio_de_Mouro.jpg")`,
      slug: "33",
    },
    {
      image: `url("/resources/images/responses/34Centro de_Formacao_profissional_da_Aldeia_de_Santa_Isabel.jpg")`,
      slug: "34",
    },
    {
      image: `url("/resources/images/responses/35centro_social_sagrada_familia.jpg")`,
      slug: "35",
    },
    {
      image: `url("/resources/images/responses/36Centro_Social_de_Reformados_e_Idosos_de_Albarraque.jpg")`,
      slug: "36",
    },
    {
      image: `url("/resources/images/responses/37centro_social_paroquial_algueirao_mem_martins.jpg")`,
      slug: "37",
    },
    { image: `url("/resources/images/responses/38cercitop.jpg")`, slug: "38" },
    {
      image: `url("/resources/images/responses/39CiNTRA_Centro_Integrado_de_Tratamento_e_Reabilitacao_em_Ambulatorio_em_Sintra.jpg")`,
      slug: "39",
    },
    {
      image: `url("/resources/images/responses/40circulos_arte_terapia.jpg")`,
      slug: "40",
    },
    {
      image: `url("/resources/images/responses/41colegio_dos_platanos.jpg")`,
      slug: "41",
    },
    {
      image: `url("/resources/images/responses/42miminhos_alegres.jpg")`,
      slug: "42",
    },
    {
      image: `url("/resources/images/responses/43creche_popular_de_rio_de_mouro.jpg")`,
      slug: "43",
    },
    /* { image: `url("/resources/images/responses/.jpg")`, slug: "44" }, */
    // { image: `url("/resources/images/responses/45CMSImi.jpg")`, slug: "45" },
    // { image: `url("/resources/images/responses/46ACAS.jpg")`, slug: "46" },
    // { image: `url("/resources/images/responses/47ARCBT.jpg")`, slug: "47" },
    // {
    //   image: `url("/resources/images/responses/48EscoteirosMerces.jpg")`,
    //   slug: "48",
    // },
    /* { image: `url("/resources/images/responses/.jpg")`, slug: "49" }, */
    // {
    //   image: `url("/resources/images/responses/50CentroSocialAlbarraque.jpg")`,
    //   slug: "50",
    // },
    /* { image: `url("/resources/images/responses/.jpg")`, slug: "51" }, */
    // {
    //   image: `url("/resources/images/responses/52CentroComunitarioParoquialRM.jpg")`,
    //   slug: "52",
    // },
    {
      image: `url("/resources/images/responses/53PEM.jpg")`,
      slug: "53",
    },
    {
      image: `url("/resources/images/responses/54externato_afonso_albuquerque.jpg")`,
      slug: "54",
    },
    {
      image: `url("/resources/images/responses/55Grupo_de_Escoteiros_178_das_Merces.jpg")`,
      slug: "55",
    },
    {
      image: `url("/resources/images/responses/56Hospital_Psiquiatrico_Sao_Joao_de_Deus.jpg")`,
      slug: "56",
    },
    {
      image: `url("/resources/images/responses/57Jangada_d_emocoes.jpg")`,
      slug: "57",
    },
    {
      image: `url("/resources/images/responses/58escola_do_povo_das_merces.jpg")`,
      slug: "58",
    },
    /* { image: `url("/resources/images/responses/.jpg")`, slug: "59" }, */
    {
      image: `url("/resources/images/responses/60jardim_escola_joao_de_deus_albarraque.jpg")`,
      slug: "60",
    },
    {
      image: `url("/resources/images/responses/61junta_uniao_freguesias_queluz_belas.jpg")`,
      slug: "61",
    },
    {
      image: `url("/resources/images/responses/62junta_de_freguesia_algueirao_mem_martins.jpg")`,
      slug: "62",
    },
    {
      image: `url("/resources/images/responses/63junta_de_freguesia_de_rio_de_mouro.jpg")`,
      slug: "63",
    },
    {
      image: `url("/resources/images/responses/64ks_escolhas.jpg")`,
      slug: "64",
    },
    {
      image: `url("/resources/images/responses/65seguranca_social.jpg")`,
      slug: "65",
    },
    /* { image: `url("/resources/images/responses/.jpg")`, slug: "66" } */
    { image: `url("/resources/images/responses/67ludoteca.jpg")`, slug: "67" },
    {
      image: `url("/resources/images/responses/68orienta-te.jpg")`,
      slug: "68",
    },
    {
      image: `url("/resources/images/responses/69portal_da_habitaco.jpg")`,
      slug: "69",
    },
    { image: `url("/resources/images/responses/70PSP.jpg")`, slug: "70" },
    { image: `url("/resources/images/responses/70PSP.jpg")`, slug: "71" },
    { image: `url("/resources/images/responses/70PSP.jpg")`, slug: "72" },
    /* { image: `url("/resources/images/responses/.jpg")`, slug: "73" } */
    {
      image: `url("/resources/images/responses/74Santa_Casa_da_Misericordia_de_Sintra.jpg")`,
      slug: "74",
    },
    { image: `url("/resources/images/responses/75seagency.jpg")`, slug: "75" },
    {
      image: `url("/resources/images/responses/76SMAS_Sintra.jpg")`,
      slug: "76",
    },
    { image: `url("/resources/images/responses/77URPITMA.jpg")`, slug: "77" },
    {
      image: `url("/resources/images/responses/78associacao_juvenil_ponte_terapia_da_fala.jpg")`,
      slug: "78",
    },
    {
      image: `url("/resources/images/responses/79associacao_juvenil_ponte_apoio_psicologico.jpg")`,
      slug: "79",
    },
    {
      image: `url("/resources/images/responses/80associacao_juvenil_ponte.jpg")`,
      slug: "80",
    },
    {
      image: `url("/resources/images/responses/81associacao_juvenil_ponte_caf_descoberta_dos_tempos_livres.jpg")`,
      slug: "81",
    },
    {
      image: `url("/resources/images/responses/82associacao_juvenil_ponte_crescer_com_amigos.jpg")`,
      slug: "82",
    },
    {
      image: `url("/resources/images/responses/83associacao_juvenil_ponte_colonia_de_ferias.jpg")`,
      slug: "83",
    },
    { image: `url("/resources/images/responses/84cercitop.jpg")`, slug: "84" },
    { image: `url("/resources/images/responses/85cercitop.jpg")`, slug: "85" },
  ];

  const image = map.find((e) => e.slug === slug)?.image;

  return image;
}

export function ResponsesList({ responses, onChange }) {

  responses.sort((a, b) =>
    desluggify(a.theme).localeCompare(desluggify(b.theme))
  );

  let reponsesCount = responses.length;

  return (
    <div className={styles.responseListWrapper}>
      {/* <BreadCrumbs parts={[{ label: "", key: "", image: "" }]} /> */}
      <div className={styles.responseListContainer2}>
        {responses.map((r, i) => (
          <ResponseCard
            {...r}
            key={r.entity_number + r.category_slug + r.address + i}
          />
        ))}
      </div>
      <button
        className={styles.returnToTop}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <span className={styles.arrow1} />
        <span className={styles.arrow1} />
      </button>
      <span className={styles.disclaimer}>
        Se precisar de mais informações dirija-se à Câmara Municipal de Sintra
        e/ou Junta de Freguesia da sua área de residência ou aceda a:{" "}
        <a href="https://www.cartasocial.pt/inicio" target="_blanck">
          Carta Social
        </a>
        .
      </span>
    </div>
  );
}

function ResponseCard({
  entity_number,
  description,
  category_slug,
  location_slug,
  conditions,
  address,
  emails,
  cellphones,
  url,
  time,
  tipology,
  capacity,
  target,
  theme,
  geolocation,
  entity
}) {
  const [state, setState] = useState();
  const [stateImg, setStateImg] = useState();
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const entityImgPath = imageFindBySlug(entity_number);
    if (entityImgPath) {
      setState(entityImgPath);
    } else {
      async function fetchImage() {

        // const res = await fetch(`/api/v1/admin/entities/${entity.id}`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json"
        //   },
        //   body: JSON.stringify({ image_name: entity[0].image_name })
        // })

        // if (res.status === 200) {
        //   setStateImg(res)
        // }

      }

      fetchImage()

    }
  }, []);


  const handleToggleActive = () => {
    setIsActive(!isActive);
  };

  const parseUrlsToLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s.]+(?:\.[^\s.]+)*)/g;
    return text.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  const groupAddressesWithGeolocations = (addresses, geolocations) => {
    const addressArray = addresses.split(";");
    const geolocationArray = geolocations.split(";");
    const result = [];
    for (
      let i = 0;
      i < Math.max(addressArray.length, geolocationArray.length);
      i++
    ) {
      const address = i === 0 ? ` ${addressArray[i]}` : addressArray[i] || "";
      const geolocation =
        i === 0 ? ` ${geolocationArray[i]}` : geolocationArray[i] || "";
      result.push({ address, geolocation });
    }
    return result;
  };

  return (
    <div className={styles.responseCardWrapper}>
      <div className={`${styles.card} ${isActive && styles.active}`}>
        <div
          className={styles.responseCardHeader}
          style={{
            backgroundColor:
              location_slug === "algueirao-mem-martins"
                ? `var(--color-amm)`
                : location_slug === "queluz-belas"
                  ? "var(--color-qlb)"
                  : location_slug === "rio-de-mouro"
                    ? "var(--color-rdm)"
                    : "",
          }}
        >
          <span className={styles.entityName}>{entity[0]?.name}</span>
          <span className={styles.location}>{desluggify(location_slug)}</span>
        </div>
        <div
          className={styles.content}
        >
          <div className={styles.description}>
            {theme ? (
              <span className={styles.bold}>{theme}</span>
            ) : (
              <span className={styles.bold}>{desluggify(category_slug)}</span>
            )}
            <div>
              <span>
                {/* {state && ( */}
                <div className={styles.entityLogo}>
                  <div style={{ backgroundImage: `url('/api/v1/images/${entity[0].image_name}')` }}>
                    {/* {stateImg && <img src={stateImg}/>} */}
                  </div>
                </div>
                {/* )} */}
                <span
                  dangerouslySetInnerHTML={{
                    __html: parseUrlsToLinks(description),
                  }}
                />
              </span>
            </div>
            {
              conditions && (
                <div style={{ marginTop: "6px" }}>
                  <span className={styles.bold}>Condições de acesso</span>
                  <div>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: parseUrlsToLinks(conditions),
                      }}
                    />
                  </div>
                </div>
              )
            }
            {capacity && (
              <div style={{ marginTop: "6px" }}>
                <span className={styles.bold}>Capacidade da resposta</span>
                <div>
                  <span>{capacity}</span>
                </div>
              </div>
            )}
            {tipology && (
              <div style={{ marginTop: "6px" }}>
                <span className={styles.bold}>Tipologia</span>
                <div>
                  <span>{tipology}</span>
                </div>
              </div>
            )}
            {target && (
              <div style={{ marginTop: "6px" }}>
                <span className={styles.bold}>Público alvo</span>
                <div>
                  <span>{target}</span>
                </div>
              </div>
            )}
          </div>
          <div className={styles.contacts}>
            {(cellphones ||
              address ||
              emails ||
              cellphones ||
              url ||
              time ||
              geolocation) && (
                <div>
                  <span className={styles.bold}>
                    Como chegar a esta resposta:
                  </span>
                  <div className={styles.contactsContent}>
                    <div className={styles.contactsSimple}>
                      {
                        cellphones && (
                          <span className="fa fa-phone">
                            {cellphones.split(/\s+|;/g).map((phoneNumber, i) => (
                              <a href={`tel:${phoneNumber}`} key={phoneNumber}>
                                <span className={styles.logo}>
                                  {" "}
                                  {`${phoneNumber}${i < cellphones.length - 1 ? "\n" : ""
                                    }`}
                                </span>
                              </a>
                            ))}
                          </span>
                        )
                      }
                      {
                        emails && (
                          <span className="fa fa-envelope">
                            {emails.split(/\s+|;/g).map((mail, i) => (
                              <a
                                href={`mailto:${mail}`}
                                key={mail}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className={styles.logo}>
                                  {" "}
                                  {`${mail}${i < emails.length - 1 ? "\n" : ""}`}
                                </span>
                              </a>
                            ))}
                          </span>
                        )
                      }
                      {
                        url && (
                          <span className="fa fa-globe">
                            {url.split(",").map((link) => {
                              let newLink = link;
                              if (
                                !link.startsWith("http://") &&
                                !link.startsWith("https://")
                              ) {
                                newLink = `http://${link}`;
                              }
                              return (
                                <a
                                  href={newLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  key={link}
                                >
                                  <span className={styles.logo}> {link}</span>
                                </a>
                              );
                            })}
                          </span>
                        )
                      }
                      {
                        time && (
                          <span className="fa fa-clock-o">
                            <span className={styles.logo}> {time}</span>
                          </span>
                        )

                      }
                      {address && groupAddressesWithGeolocations(address, geolocation).map(
                        (pair, index) => (
                          <div key={index}>
                            {pair.address && (
                              <div>
                                {pair.address.includes(":") ? (
                                  <>
                                    <span className={styles.bold}>
                                      {pair.address.split(":")[0]}:
                                    </span>
                                    <span className="fa fa-map-marker">
                                      <a
                                        // href={`https://maps.google.com/?q=${
                                        //   pair.address.split(":")[1]
                                        // }`}
                                        href={`https://maps.google.com/?q=${!pair.geolocation || pair.geolocation.length === 0 ? pair.address.split(":")[1] : pair.geolocation}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <span className={styles.logo}>
                                          {pair.address.split(":")[1]}
                                        </span>
                                      </a>
                                    </span>
                                  </>
                                ) : (
                                  <span className="fa fa-map-marker">
                                    <a
                                      // href={`https://maps.google.com/?q=${pair.address}`}
                                      href={`https://maps.google.com/?q=${!pair.geolocation || pair.geolocation.length === 0 ? pair.address : pair.geolocation}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span className={styles.logo}>
                                        {pair.address}
                                      </span>
                                    </a>
                                  </span>
                                )}
                              </div>
                            )}
                            {/* {pair.geolocation !== "" && (
                            <span className="fa fa-street-view">
                              <a
                                href={`https://maps.google.com/?q=${pair.geolocation}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span className={styles.logo}>
                                  {pair.geolocation}
                                </span>
                              </a>
                            </span>
                          )} */}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
        <a
          className={styles.more}
          style={{
            background:
              location_slug === "algueirao-mem-martins"
                ? `var(--color-amm)`
                : location_slug === "queluz-belas"
                  ? "var(--color-qlb)"
                  : location_slug === "rio-de-mouro"
                    ? "var(--color-rdm)"
                    : "",
          }}
          onClick={handleToggleActive}
        >
          {isActive ? "Ver menos" : "Ver mais"}
        </a>
      </div>
    </div>
  );
}
