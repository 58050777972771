import { Button } from "@mui/material"

interface IRemoveButtonProps {
    onClick: () => any
}

export function RemoveButton({ onClick }: IRemoveButtonProps) {
    return (
        <Button variant="outlined" type="button" onClick={onClick} style={{borderRadius: "0.75rem"}}>
            -
        </Button>
    )
}