import { motion } from "framer-motion";
import SearchIcon from "@mui/icons-material/Search";
import { FormEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { Mapa } from "./Map";
import styles from "../styles/HeroSection.module.css";
import { SearchBar } from "./SearchBar";

type TDemographicSlugs = "children" | "youth" | "families" | "seniors";
type TLocationSlugs = "algueirao-mem-martins" | "queluz-belas" | "rio-de-mouro";

const locations = [
  {
    label: "Algueirão-Mem Martins",
    value: "algueirao-mem-martins",
    color: "#0097B2",
  },
  { label: "Queluz e Belas", value: "queluz-belas", color: "#1A9E61" },
  { label: "Rio de Mouro", value: "rio-de-mouro", color: "#c9ab57" },
];

const demographics = [
  { label: "Crianças", value: "children" },
  { label: "Jovens", value: "youth" },
  { label: "Famílias", value: "families" },
  { label: "Seniores", value: "seniors" },
];

interface IHeroProps {
  activeDemographic: string;
  activeLocation: string;
  handleChangeDemographic: (e: any) => any;
  handleChangeLocation: (e: any) => any;
  onSearch: (e: any) => any;
}

export function Hero({
  activeDemographic,
  activeLocation,
  onSearch,
  handleChangeDemographic,
  handleChangeLocation,
}: IHeroProps) {
  return (
    <div
      className={`relative bg-no-repeat bg-cover flex flex-col justify-center items-center gap-4`}
      style={{
        backgroundImage: `url(/resources/images/hero/gps_hero_bg.webp)`,
      }}
    >
      <div
        className={`absolute top-0 right-0 left-0 bottom-0`}
        style={{ backgroundColor: "#004956", opacity: 0.8 }}
      ></div>
      {/* <Mapa color1={"red"} color2={"hotpink"} color3={"yellow"} /> */}

      <HeroBar activeLocation={activeLocation as TLocationSlugs} />
      <HeroContent
        onSearch={onSearch}
        handleChangeDemographic={handleChangeDemographic}
        handleChangeLocation={handleChangeLocation}
        activeLocation={activeLocation as TLocationSlugs}
        activeDemographic={activeDemographic as TDemographicSlugs}
      />
    </div>
  );
}

interface IHeroBarProps {
  activeLocation?: TLocationSlugs;
}

function useWidth() {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () =>
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return width;
}

export function HeroBar({ activeLocation }: IHeroBarProps) {
  const width = useWidth();

  return (
    <div
      className={"z-10 px-10 mb-10 w-full flex gap-4 justify-between"}
      style={{ backgroundColor: "rgb(0, 73, 86)", opacity: "0.9" }}
    >
      <div className={"flex flex-row items-center"}>
        <a href="/">
          <img
            src="/resources/images/logo/logo_gps_1.png"
            className={"min-w-24 w-40 max-w-48"}
          />
        </a>
      </div>
      <div className={"flex flex-col font-bold"}>
        {/* {locations.map(({ label, value, color }) => (
                    <span
                        style={{
                            color: activeLocation === value ? color : "white"
                        }}
                    >{label}</span>
                ))} */}
        <Mapa
          size={width < 1000 ? 100 : 150}
          algueirao={
            activeLocation === "algueirao-mem-martins" &&
              locations.find((l) => l.value === activeLocation)?.color
              ? locations.find((l) => l.value === activeLocation)?.color
              : "transparent"
          }
          riodemouro={
            activeLocation === "rio-de-mouro" &&
              locations.find((l) => l.value === activeLocation)?.color
              ? locations.find((l) => l.value === activeLocation)?.color
              : "transparent"
          }
          queluzbelas={
            activeLocation === "queluz-belas" &&
              locations.find((l) => l.value === activeLocation)?.color
              ? locations.find((l) => l.value === activeLocation)?.color
              : "transparent"
          }
        />
      </div>
    </div>
  );
}

interface IHeroContentProps {
  activeDemographic: TDemographicSlugs;
  activeLocation: TLocationSlugs;
  handleChangeDemographic: (e: any) => any;
  handleChangeLocation: (e: any) => any;
  onSearch: (e: any) => any;
}

function HeroContent({
  activeDemographic,
  activeLocation,
  onSearch,
  handleChangeDemographic,
  handleChangeLocation,
}: IHeroContentProps) {
  return (
    <div
      className={`flex flex-col justify-center items-center gap-4 h-fit pb-16`}
    >
      <LocationNavigation
        onClick={handleChangeLocation}
        selected={activeLocation}
      />
      {activeLocation && activeLocation.length && (
        <DemographicNavigation
          onClick={handleChangeDemographic}
          selected={activeDemographic}
        />
      )}
      {activeLocation && activeLocation.length && (
        <SearchBar onSearch={onSearch} />
      )}
    </div>
  );
}

interface ISearchBarProps {
  onSearch: (e: any) => any;
  selected?: boolean;
  onClick?: (e: MouseEvent) => any;
}

// function SearchBar({ onSearch
//   }: ISearchBarProps) {
//   const ref = useRef<HTMLInputElement | null>(null);
//   const [value, setValue] = useState("");
//   const [focus, setFocus] = useState(false);
//   const [isInputVisible, setInputVisible] = useState(false);

//   const handleSubmit = (e: FormEvent) => {
//     e.preventDefault();
//     onSearch(value);
//     setInputVisible(false)
//   };

//   const handleIconClick = () => {
//     setInputVisible(true);
//     setHover(false)
//   };

//   const [hover, setHover] = useState(false);
//   return (
//     <div className={styles.wrapper}>
//       {!isInputVisible && (
//         // <div className={styles.iconContainer} onClick={handleIconClick}>
//         //   <SearchIcon />
//         // </div>
//         <motion.div
//       className={`border-2 border-white relative flex-1 shadow-md cursor-pointer overflow-hidden flex items-center justify-center sm:text-lg min-w-24 max-w-48 w-10 py-1 px-1 sm:py-4 sm:px-8 rounded-xl ml-auto`}
//       onMouseEnter={() => setHover(true)}
//       onMouseLeave={() => setHover(false)}
//       onClick={handleIconClick}
//       animate={{
//         color: hover ? "black" : "white",
//         borderColor: hover ? "white" : "white",
//       }}
//     >
//       <motion.div
//         className={`absolute top-0 right-0 left-1/2 -translate-x-1/2 bottom-0 rounded-full w-0 h-0`}
//         animate={{
//           width: hover ? "300%" : "0%",
//           height: hover ? "inherit" : "0%",
//         }}
//         style={{ backgroundColor: "white" }}
//       />
//       <span className={`flex z-10 min-h-fit justify-center items-center`}>
//       <SearchIcon />
//       </span>
//     </motion.div>
//       )}
//       {isInputVisible && (
//         // <div className={`${styles.searchInput} ${styles.active}`}>
//         <div className={`relative flex-1 flex items-center justify-center sm:text-lg min-w-24 max-w-48 py-1 px-1 sm:py-4 sm:px-8 rounded-xl`}>
//           <form onSubmit={(e) => handleSubmit(e)}>
//             <input
//               className={styles.inputTransition}
//               // onBlur={() => setInputVisible(false)}
//               onChange={(e) => setValue(e.target.value)}
//               value={value}
//               placeholder="Procurar..."
//             />
//             <button className={`${styles.iconContainer} border-none bg-transparent`}>
//               {/* <SearchIcon /> */}
//               <motion.div
//             className={`border-2 border-white relative flex-1 shadow-md cursor-pointer overflow-hidden flex items-center justify-center sm:text-lg min-w-24 max-w-48 w-10 py-1 px-1 sm:py-4 sm:px-8 rounded-xl ml-auto`}
//             // onClick={onClick}
//             initial={{
//                 color: "white",
//                 borderColor: "white",
//             }}
//             whileHover={{
//                 color: "black",
//                 borderColor: "white",
//             }}
//         >
//             <motion.div
//                 className={`absolute top-0 right-0 left-1/2 -translate-x-1/2 bottom-0 rounded-full w-0 h-0`}
//                 initial={{
//                     width: "0%",
//                     height: "0%",
//                 }}
//                 whileHover={{
//                     width: "300%",
//                     height: "inherit",
//                 }}
//                 style={{ backgroundColor: "white" }}
//             />
//             <span className={`flex z-10 min-h-fit justify-center items-center`}>
//                 <SearchIcon />
//             </span>
//         </motion.div>
//             </button>
//           </form>
//         </div>
//       )}
//     </div>
//   );

//   // return (
//   //     <form className={`z-10 pt-16`} onSubmit={(e) => handleSubmit(e)} >
//   //         <input className={`shadow-md rounded-xl p-2`} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)} ref={ref} onChange={(e) => setValue(e.target.value)} />
//   //         <button className={`border-none [&>svg]:fill-white`}>
//   //             <SearchIcon />
//   //         </button>
//   //     </form>
//   // )
// }

interface ILocationNavigationCardProps {
  children: string;
  color: string;
  selected?: boolean;
  onClick?: (e: MouseEvent) => any;
}

function LocationNavigationCard({
  children,
  color,
  selected,
  onClick,
}: ILocationNavigationCardProps) {
  const [hover, setHover] = useState(false);
  return (
    <motion.div
      className={`border-2 border-white relative flex-1 shadow-md cursor-pointer overflow-hidden flex items-center justify-center sm:text-lg min-w-24 max-w-48 w-40 py-1 px-1 sm:py-4 sm:px-8 rounded-xl text-center font-bold`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      animate={{
        color: hover || selected ? "white" : "white",
        borderColor: hover || selected ? "white" : "white",
      }}
    >
      <motion.div
        className={`absolute top-0 right-0 left-1/2 -translate-x-1/2 bottom-0 rounded-full w-0 h-0`}
        animate={{
          width: hover || selected ? "300%" : "0%",
          height: hover || selected ? "inherit" : "0%",
        }}
        style={{ backgroundColor: color }}
      />
      <span className={`flex z-10 min-h-fit justify-center items-center`}>
        {children}
      </span>
    </motion.div>
  );
}

interface ILocationNavigationProps {
  selected: TLocationSlugs;
  onClick: (e: any) => any;
}

function LocationNavigation({ selected, onClick }: ILocationNavigationProps) {
  return (
    <div className={`flex flex-row gap-2 w-fit flex-wrap`}>
      {locations.map(({ label, value, color }) => (
        <LocationNavigationCard
          onClick={() => onClick(value)}
          color={color}
          selected={value === selected}
        >
          {label}
        </LocationNavigationCard>
      ))}
    </div>
  );
}

interface IDemographicNavigationProps {
  selected?: TDemographicSlugs;
  onClick: (e: any) => any;
}

function DemographicNavigation({
  selected,
  onClick,
}: IDemographicNavigationProps) {
  return (
    <div className={`flex flex-row gap-2 w-fit flex-wrap`}>
      {demographics.map(({ label, value }, i) => (
        <DemographicNavigationCard
          key={label + value + i}
          onClick={() => onClick(value)}
          selected={value === selected}
          imagePath={value}
        >
          {label}
        </DemographicNavigationCard>
      ))}
    </div>
  );
}

interface IDemographicNavigationCardProps {
  children: string;
  selected?: boolean;
  imagePath: string;
  onClick?: (e: MouseEvent) => any;
}

function DemographicNavigationCard({
  children,
  selected,
  imagePath,
  onClick,
}: IDemographicNavigationCardProps) {
  const [hover, setHover] = useState(false);
  return (
    <motion.div
      className={`border-2 relative flex-1 shadow-md cursor-pointer overflow-hidden flex items-center justify-center sm:text-lg min-w-24 max-w-48 w-40 py-2 px-1 sm:py-6 sm:px-8 rounded-xl text-center font-bold`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      animate={{
        color: hover || selected ? "white" : "white",
        borderColor: hover || selected ? "white" : "white",
      }}
    >
      <motion.div
        className={`absolute top-0 bg-center right-0 left-1/2 -translate-x-1/2 bottom-0 bg-no-repeat`}
        animate={{
          backgroundSize: hover || selected ? "100%" : "0%",
          opacity: hover || selected ? 1 : 0,
        }}
        style={{
          height: "inherit",
          width: "inherit",
          backgroundImage: `url(/resources/images/demographics/${imagePath}.png)`,
        }}
      />
      <span
        className={`flex z-10 drop-shadow-xl min-h-fit justify-center items-center`}
      >
        {children}
      </span>
    </motion.div>
  );
}

interface IBreadCrumbsProps {
  parts: { label: string; key: string; image: string }[];
  onClick: (e: any) => any;
}

export function BreadCrumbs({ parts, onClick }: IBreadCrumbsProps) {
  const goBack = () => { };

  return (
    <div className={`flex gap-3 [&>svg]:h-24`}>
      <BackButton onClick={() => goBack()} />
      {parts &&
        parts.length > 0 &&
        parts.map(({ label, key, image }) => (
          <BreadCrumbCard onClick={() => onClick(key)} imagePath={image}>
            {label}
          </BreadCrumbCard>
        ))}
    </div>
  );
}

interface IBreadCrumbCardProps {
  children: string;
  imagePath: string;
  onClick?: (e: MouseEvent) => any;
}

function BreadCrumbCard({
  children,
  imagePath,
  onClick,
}: IBreadCrumbCardProps) {
  const [hover, setHover] = useState(false);
  return (
    <motion.div
      className={`border-2 relative flex-1 shadow-md cursor-pointer overflow-hidden flex items-center justify-center sm:text-lg min-w-24 max-w-48 w-40 py-2 px-1 sm:py-6 sm:px-8 rounded-xl text-center font-bold`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
      animate={{
        color: hover ? "white" : "white",
        borderColor: hover ? "white" : "white",
      }}
    >
      <motion.div
        className={`absolute top-0 bg-center right-0 left-1/2 -translate-x-1/2 bottom-0 bg-no-repeat`}
        animate={{
          backgroundSize: hover ? "100%" : "0%",
          opacity: hover ? 1 : 0,
        }}
        style={{
          height: "inherit",
          width: "inherit",
          backgroundImage: `url(/resources/images/categories/${imagePath}.png)`,
        }}
      />
      <span
        className={`flex z-10 drop-shadow-xl min-h-fit justify-center items-center`}
      >
        {children}
      </span>
    </motion.div>
  );
}

interface IBackButtonProps {
  onClick: (e: any) => any;
}

function BackButton({ onClick }: IBackButtonProps) {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
      height="800px"
      width="800px"
      version="1.1"
      id="Capa_1"
      viewBox="0 0 219.151 219.151"
    >
      <g>
        <path d="M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575   C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575   c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z" />
        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008   c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825   c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628   c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z" />
      </g>
    </svg>
  );
}

// interface IHeroBarProps {
//     activeLocation: "algueirao-mem-martins" | "queluz-belas" | "rio-de-mouro"
// }

// interface IHeroContentProps {
//     activeDemographic: "children" | "youth" | "families" | "seniors"
// }

// function HeroContent({ activeDemographic }: IHeroContentProps) {
//     return (
//         <div className={"flex flex-col relative"}
//             style={{
//                 backgroundImage: `url(/resources/images/hero/gps_hero_bg.jpg)`,
//             }}
//         >
//             <div className={`absolute right-0 left-0 bottom-0 top-0 bg-slate-950 opacity-80 pointer-events-none`}></div>
//             <div className={"flex z-10 flex-wrap gap-4 justify-center pt-8 px-8 sm:px-16 sm:pt-16 w-full"}>
//                 {demographics.map(({ label, value }) => (
//                     <motion.div
//                         className={`
//                         relative
//                         bg-cover bg-no-repeat
//                         w-24 h-20 sm:w-48 sm:h-40
//                         font-bold text-white
//                         rounded-xl
//                         overflow-hidden
//                         cursor-pointer
//                         bg-center
//                         text-2xl

//                         ${activeDemographic === value ? "border-4 border-blue-400" : ""}
//                         `}
//                         whileHover={{
//                             backgroundSize: "105%",
//                             scale: 1.05,
//                             outline: "4px solid white",
//                             transition: { duration: 3 },
//                         }}
//                         style={{
//                             backgroundSize: "0%",
//                             backgroundImage: `url(/resources/images/demographics/${value}.png)`
//                         }}
//                     >
//                         <motion.div>

//                         </motion.div>
//                         <span className={`
//                         absolute top-0 left-0 right-0 bottom-0
//                         flex flex-col justify-center items-center
//                         bg-black
//                         bg-opacity-50
//                         `}>{label}</span>
//                     </motion.div>
//                 ))}
//             </div>
//             <div className={`flex z-10 justify-center gap-2 items-center py-8 px-8 sm:px-16 sm:py-16`}>
//                 <input
//                     className={`
//                     border border-primary-gray
//                     py-3
//                     px-5
//                     rounded-lg
//                     w-full
//                     max-w-md
//                     focus:border-secondary-gray
//                     `}
//                     placeholder="Ex: educação" />
//                 <SearchIcon
//                     // onClick={() => handleStateChange("search", search)}
//                     style={{
//                         minHeight: 25, height: 55, maxHeight: 60,
//                         width: "auto",
//                         fill: "white",
//                     }}
//                     className={`cursor-pointer`}
//                 />
//             </div>
//         </div>
//     )
// }
