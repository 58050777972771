import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import styles from './styles/App.module.css';
import { useQueryParams } from './hooks/useQueryParams';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SearchButtons, SearchButtons2 } from './components/NavButtons';
import { LoadingSpinner } from './components/Loading';
import { ResponsesList } from './components/Responses';
import { CategoriesList } from './components/Categories';
import { Index } from './components/Index';
import { Footer } from './components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import { SiteLogoSVG } from './components/svg/SiteLogo';
import { HeaderBar } from './components/HeaderBar';
import { TaxonomiesTags } from './components/TaxonomiesTags';
import { APP_KEY } from './variables';
import { ContactUs } from './components/ContactForm2';
import { Container, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Hero, HeroBar } from './components/HeroSection';

import { motion } from "framer-motion"
import { Mapa } from './components/Map';

interface IQueries {
  location?: string
  demographic?: string
  category?: string
}

const topNav = [
  { label: "Algueirão-Mem Martins", value: "algueirao-mem-martins" },
  { label: "Queluz e Belas", value: "queluz-belas" },
  { label: "Rio de Mouro", value: "rio-de-mouro" },
]

const lowerNav1 = [
  { label: "Crianças", value: "children" },
  { label: "Jovens", value: "youth" },
  { label: "Famílias", value: "families" },
  { label: "Seniores", value: "seniors" },
]

function App() {
  const [responses, setResponses] = useState([]);
  const location2 = useLocation()
  const [state, setState] = useState({ location: "", demographic: "", category: "" })
  const [search, setSearch] = useState("")
  const [searchParams, setSearchParams] = useSearchParams({});
  const [loading, setLoading] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);

  //Changes Query
  const handleRouteChange = (obj: any) => {
    const keys = Object.keys(obj)
    keys.forEach(k => {
      if (obj[k] === "" || obj.location === "") {
        delete obj[k]
      }
    })
    setSearchParams(obj)
  }

  const changeCategory = () => {
    setState(ps => {
      const copy = ps
      copy.category = ""
      handleRouteChange(copy)
      setResponses([])
      return copy
    })
  }

  //Changes state then query
  const handleStateChange = (key: "location" | "demographic" | "category" | "search", value: string) => {
    updateQueries(key, value)
  }

  function updateQueries(key: "location" | "demographic" | "category" | "search", value: string, refresh?: boolean) {
    const currentQueries = extractQueries()
    if (currentQueries[key] === value) {
      //Cleaning value
      // delete currentQueries[key]
      if (key === "location" || key === "demographic") {
        //if cleaning location, clean others
        // delete currentQueries["demographic"]
        // delete currentQueries["category"]
        delete currentQueries["category"]
      }
    } else {
      //Set / update value
      currentQueries[key] = value
    }

    if (key === "location") {
      delete currentQueries["demographic"]
      delete currentQueries["category"]
      delete currentQueries["search"]
      setResponses([])
    }

    if (key === "demographic") {
      //if cleaning demographic, clean others
      delete currentQueries["category"]
      delete currentQueries["search"]
      setResponses([])
    }

    if (refresh) {
      if (!state.location) {
        delete currentQueries["category"]
      }
    }

    if (key === "location" || key === "demographic") {
      delete currentQueries["category"]
      setResponses([])
    }

    setState(currentQueries)
    setSearchParams(currentQueries)

    if ((currentQueries.location && currentQueries.category) || currentQueries.search) {
      fetchData(currentQueries)
    } else if (currentQueries.demographic && !currentQueries.category) {
      setResponses([])
    }
  }

  async function fetchData(queries: any) {
    setLoading(true)
    console.log("fetching responses")
    const res = await fetch(`/api/responses`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `bearer ${APP_KEY}`
      },
      body: JSON.stringify({ ...queries })
    })

    if (res.status === 200) {
      const body = await res.json()
      if (body.responses) {
        setResponses(body.responses)
      }
      if (body.message) {
        toast.error(body.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
    setLoading(false)

  }

  function extractQueries() {
    const split_queries = location2.search.split("?").join("").split("&")

    const split_query_names: any = split_queries.reduce((acc, curr, i) => {
      const split_query = curr.split("=");
      if (["location", "demographic", "category"].includes(split_query[0]) && split_query.length > 1) {
        return { ...acc, [split_query[0]]: split_query[1] }
      }
      return { ...acc }
    }, {})

    return split_query_names
  }

  useEffect(() => {
    updateQueries("category", "", true)
  }, [])

  if (submittingForm) {
    return (
      <div className="App">
        <HeroBar />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <ContactUs />
        </div>
        <Footer />
      </div>
    )
  }

  return (
    <div className={styles.App}>
      <Hero
        handleChangeDemographic={(value: string) => handleStateChange("demographic", value)}
        handleChangeLocation={(value: string) => handleStateChange("location", value)}
        activeLocation={state.location}
        activeDemographic={state.demographic}
        onSearch={(search) => handleStateChange("search", search)}
      />
      {loading ?
        <LoadingSpinner />
        : responses.length > 0 ?
          <ResponsesList responses={responses} onChange={(value: string) => changeCategory()} />
          : state.location ?
            <CategoriesList demographic={extractQueries().demographic} location={extractQueries().location} onChange={(value: string) => handleStateChange("category", value)} /> :
            <Index setSubmittingForm={setSubmittingForm} />
      }
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default App;
