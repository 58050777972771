import React from "react";
import styles from "../styles/Button.module.css";
import { motion } from "framer-motion"

interface IButtonProps {
  children: string | JSX.Element;
  onClick?: (e: any) => any;
  disabled?: boolean;
  isActive?: boolean;
}

const ButtonDef = ({
  children,
  onClick,
  disabled,
  isActive,
  ...props
}: IButtonProps) => (
  // <button className={styles.button} onClick={onClick} disabled={disabled} {...props}>
  //     <span className={styles.buttonLabel}> {children} </span>
  // </button>
  <motion.button
    disabled={disabled}
    className={`border-2
     flex shadow-md cursor-pointer overflow-hidden
      items-center justify-center
      
      min-w-24
      max-w-48
      w-70 py-2 px-1
      rounded-xl text-center font-bold m-10
      

      sm:py-6 sm:px-8 
      sm:text-lg
         `}

    initial={{
      backgroundColor: !disabled ? "rgb(255, 255, 255)" : "rgb(94, 94, 94)",
      color: !disabled ? "rgb(0, 73, 86, 1)" : "rgb(255, 255, 255)"
    }}
    whileHover={{
      backgroundColor: !disabled ? "rgb(0, 73, 86, 1)" : "rgb(94, 94, 94)",
      color: !disabled ? "rgb(255, 255, 255)" : "rgb(255, 255, 255)",
    }}

    onClick={onClick}
  >
    {children}
  </motion.button>
);

export default ButtonDef;
