import styles from '../styles/Loading.module.css';

export function LoadingSpinner() {
    return (
        <div className={styles.gps_nav_wrapper}>
            LOADING
        </div>
    )
}

